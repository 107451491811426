.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #00adef;
  background: linear-gradient(to right, #00adef 10%, rgba(32, 164, 164, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.loader:before {
  width: 50%;
  height: 50%;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ""; }

.loader:after {
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

input,
textarea,
select,
.input-group-text {
  border: 1px solid #ececec !important;
  border-radius: 0.2em !important;
  background-color: #fff; }

input[type="checkbox"],
input[type="radio"] {
  margin: 0.3rem; }

.form-control {
  height: 38px;
  font-size: 14px; }

.form-control:disabled {
  background-color: #f7f7f7 !important;
  color: #a7a7a7; }

.form-control[readonly] {
  background-color: #fff; }

.form-row {
  align-items: center; }

option.placeholder {
  color: #999; }

textarea {
  max-height: 200px;
  min-height: 38px; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAWCAYAAAClrE55AAAAAXNSR0IArs4c6QAAAx9JREFUSA3Nll9I01EUx90fXbkWOiiC2cOIoF6CtukUEaaQEO4hhUGP1UPhW/RUECTRW1SQvQUhUQ/1FGsQjCIsnP82jXzooaAsEOkhsG2u6f70OcP762qu5tDswPHee/5+z7nn7mdNzf9GPp8v8K8xtba2HlY5LbIBxJVisTjkcrm+zM3NTSvlVq7kPJbP58fIuUDOMbOeDDC3vV7vDVaTLt/sPTn6yfFE4rI2yroKCGc7inOgjbS1te0Ug82kgYEBM7HvEPM6XK/HLgExmUyzCAuiYG9n6VxaWko0NzfvE9lmUHd3tz0SiUQp9BTxJIdQEV6QjXEFtOsTZ0lsg4WW4e9Wq7VrfHz8bUlS5R+666Kwl7g3waVOU3C+UCh8n5qackpY42rq6uo8KD8g+yEKqBZ25nK5GCB7SpIq/vj9fm82m50htht3BWKZznylSK8KaQAZHR395nQ6/ThMw4srBtIxO+fHHo/nvHKqdKWAExQyjH8jia0rfln2n81ms2diYuKjimUAEUE0Gk273e4ALRvmmFZGONYT7BqB74ZCodKTV7pyK/N1Ed1DWM2DmGaI887hcPgmJyfnRaDImBElkFWmOxwOD+HUx1EPlEaWsNlswZGRkaTuo/aArQX4Pex61/guIoujO55IJFTHlduvYTUk2oandpPunCWADkZmaB4wgVgsNquZ1wQCgYZkMvkM2RFYf55SwHNAhAAhj+A3WnU1a7XxePwCd3kVuV7BDs5NDOAbqvcrH/YHUqnUDOejsAGC5Gn4UTAY7CsHQmKsezWi0InOnCHYIDIjgeipUtp9mq5JZ6LSOez0GZI5GwTAJbH/E1UERAK0tLT08m14wHYVGM7SrRy8G9ZJQF6mq7d0Ybl9xUAkAGC6eI5hqbxcwBW5gOgHxP2/2BnqDQERL8D46MwLtrvg9WZskbk6yfN8KvaV0oaBSGAG8xAVv2YeGjiqHyr5kqYsFksPIF5VCkDZVQVEnPnB2k/iGLyXo4BJAq6T66jq/5mqgQiYjo6OPZlMRjrj4LsR4OP4XuTbQu3t7Q4+bAe3JflWJP0JMkkoQfnc3P0AAAAASUVORK5CYII=);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding: 0 30px 0 0.75rem !important;
  font-size: "14px";
  height: "38px"; }

select::-ms-expand {
  display: none; }

button:disabled {
  opacity: 0.65; }

@font-face {
  font-family: 'CorporateACon-Reg';
  src: 
    url(/static/media/CorporateACon-Reg.af10a68a.OTF) format('opentype'),
    url(/static/media/DaimlerCAC-Regular.fb54a956.woff) format('woff');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'CorporateS-Regular';
  src: 
    url(/static/media/CorporateS-Regular.89765fca.TTF) format('truetype'),
    url(/static/media/DaimlerCS-Regular.91e7eb63.woff) format('woff');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'CorporateS-Bold';
  src: 
    url(/static/media/CorporateS-Bold.8fbe15f5.TTF) format('truetype'),
    url(/static/media/DaimlerCS-Bold.1b68f466.woff) format('woff');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'CorpoSLig-Bold';
  src: 
    url(/static/media/CorpoSLig-Bold.d6c21db4.TTF) format('truetype'),
    url(/static/media/DaimlerCS-Bold.1b68f466.woff) format('woff');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'CorpoSDem-Bold';
  src: 
    url(/static/media/CorpoSDem.119db965.TTF) format('truetype'),
    url(/static/media/DaimlerCS-Bold.1b68f466.woff) format('woff');
  font-weight: normal;
	font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "CorporateS-Regular",  sans-serif;
}
.offer-category_update-text {
  text-align: center;
  min-width: 380px;
  padding: 50px 0 0 0;
  font-family: "CorporateS-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  margin: 0; }

.m-t-5 {
  margin-top: 5px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.m-r-35 {
  margin-right: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.m-r-40 {
  margin-right: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.m-t-45 {
  margin-top: 45px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.m-l-45 {
  margin-left: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.m-r-45 {
  margin-right: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.m-r-50 {
  margin-right: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.m-t-75 {
  margin-top: 75px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.m-b-75 {
  margin-bottom: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.m-l-75 {
  margin-left: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.m-r-75 {
  margin-right: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.m-t-100 {
  margin-top: 100px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.m-l-100 {
  margin-left: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.m-r-100 {
  margin-right: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

/* Required field (*) color */
.color-primary {
  color: #00adef; }

/* Form update button */
.update-text {
  font-family: "CorporateS-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  margin-bottom: 0pt;
  margin-left: 20px; }

/* Input with icon */
.input-with-currency-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAkCAYAAAAHKVPcAAAAAXNSR0IArs4c6QAABPZJREFUWAnNl01oXFUUxzOTSWoiqYUQFYJmHLVCxYWZTGISPxDEEOMnKCiC4Nou3AldaNy5ERciuiwofjCIK0k2QiCtYZIZkCh+VLHTaCtqEIxonUky8Xde37k99773WrtQeuHlnvM/H/c/95537ktX12UwcpVK5b69vb3Dlkt3d/cztVpty2JWnpycHN7e3n7DYGfr9fpTRk+I5XJ5NJfLvaiGnp6e51ZWVk6LXpAHEo+oUeZOp1Ni+sxiVm632/egezHj4+NHVldXT1q/QJ4w6+xhc6TzsPs+cO4CuyHErI59yuois0AlxKxOzEGjb7ALZ1XPDw4OnkIRZm6wE0WnpAvTIUzMeIgFuiXxjbXlFxYWWrCMzsYYMndienp6AL/bjG8kkuOCO8FOORL4nrDxeVFwCM+yaJ2s3Gq17sC/22Iig43Oz89H+UIbRdnDwkWDezuRm5iY2L+7u/sWSVyh4PxloVCYNEFOxPcFfI84wAgsNMGb9bWBInFnZ+cmhIbBH0P+uNFobAtWwOF3Y1TxUAau9tQZcjXiUm0B+FE+n38W7Kjgqdsnhv9zSJ94ledanqftwmzt+/yysGCvx+cJ9cPnZ3yuUZ35F563ja7iwwg3x8oZ5veI/UKNOREoHEl+SkGZcXqcLvihxcbGxp5n0dcUw+dl9JeMvrW2tnYA3HvliavhF73C2D4g75MaI3N0HKVSSX5xVCTGKMTCcZcBzpCwanR5Q/ZT6PqLnQn8FlWQvTdD8IhEtVrdRd5QR5lxHrF6jN1psGNzc3Nfof9pMGn5ZatDSo7rKoOlkxAHflXYK7yd4G6QZnO1SXacvtAhzrtjIO+RgJTbBYnl1U+QkFv0FTES/CDPrSLH4zSv0TuqxMkeVZ3Fpbg2wB8Asx30O+JcLZHzEM9DJu514v7Cp8pN3RA8R1F6RaTO//UsfYIiPirrXBZ9It/b2zsoD+3WbZnuAtjdsd2reLZzXuP6+/ulTloaIzP2w2pHtrX2puLFYvFdjSlwr/8mytTU1OfcC4pHM2d5QOzUjXd1g3+iceJIH1gHc7co8kGxxxfXdSbpuo1T3B3H8PDwj7D2Gj/J9DW1JNoDAwN1TRDPUYEZbDSWbySHdGUdiTdDDI5E3Ct+UG+ZSVCMZ9cfINpYWlr6W3Ad+IUkbpdrHV/v9cQ/ccNKDkdCFJLZ85OzHZmdnd0HPiZ2GcjHz0nn/wqx81okXbm4uCgE7IfMH1zdPwV+keqRIFkzcCpubm5K89mnOD7HVNYZYnIZecVJfUmc3YnUXZAcHgmSNQXUgT7C445C8L6+vk/VrrN8nEBuXXWZiSvzWBKp9SC+HgkaiHcc2Id47hdHGSx0Ynl5+ddzWuKvdyRCAg93HOiZJGzlyuXTDFMTfK/BEvWgNvw8EhCugF2hdvR/dxw0knAnJIfdrUwSLOKRsAQkCXrmTtgFumZmZqR62xKUMRJFqX4skihOtTF36C3fGt0Toy8ri9Dl5H8Cr03H9k0KUGokc9A5VyHjOqc6sksn+ZoqqR7O3k6IkYBm6BTjibcixc87EmPPrAfxSZAAS6sL8c2sBzHKYBeySGTWg8QlSGTtBL4XJREWpywg40JFKfYECQKaYghGa2hoKLy0ApdosdTihNylHQffEInjIEld/nFOrBoAaZ1TXC55J9IaFnkuehSGj1cX/ICtrItLY/4BQHnuwWL8CvoAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 12px;
  padding-left: 30px !important; }

.input-with-phone-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAAAv5JREFUWAnNlm9oT1EYx8ew+RGT/1a8UWomL70ZfhNbKeWFpLwhsfJCVhoiRUgpScMbvBYpiRA2pSHiBaaQtZJ/pfzL3zE+X51Tt9v53XPvdk/2rc/Oved57vM89/7Oec7KygagCtR0AJ5AL/yJoPtuaIWxEEwVRH4A0eSlrh/jNypUJZtNEY8YZ8OgWCLd18B9UIE7IYguEFUJFnui1xk/FZyrBptoVWb85Il+F3sPzIRJHt9MZlvIU/OUEiTpB8ZO41BMcsxqs4XYT+0rRPHbTJL6rMmS/G0h9i21UH06axzm+xz7Yh/NQ/rtf4Jvaw7Bx/aZkVznIvtFPhKtA4ZCgyeyFqkK+QVaM7nIFqJg503EJQmR1fhOQDloy+sr5q7pRNSbfoExjuiVzF0G9Zu3MBWC6RKRlWiTI8NBY3vDqC4bVOqsKqQL9PmjauFGtucwImoIca0zRaevEq6PJdBusWdNa8wW5HYpUVXIO4ivlVnMaafIvgqC6yoZlOyQI1OTsWnHNDrsuU7VEk2JtIsWOiLvYU6FfoZFDnuuU9uIpmSvYLwj8nFjV8FrHfblzLWBFr7GXaCXKkAmqdm1g4q5BsMgKi3s3SC7OAJq+Zo/DHY+PuoYuQnbYRqkUjVer0HBTkK0E3P7T6v5q+Dy6YYz5vor40bQia4NcAz0daOF6f+fBkglncgfQAGOgt44Lu2mO2CTqDsviDuZ+8mM6ld6MfmrS+vQTaV5eH0DPXgKKiAuNUB15Fsg/zRqx0kxl6Vxtj5FLt6DHrwBE6G/2kcAxWtx/ealgl/HUAcvzPiQMemkxuxVr/Eoz1KInumEOXAFtKXPgRbhBOiXshaiZNpFjdAM32ENPIMtUAn/RTPIqq+i31m8hB2gnZFGe3HSc1vTOKfxqcfpHtiC1FdOw0oYB6Vke86KUg59nS/yoLZ3D9iifnN9G3SANsFc0JfUT6nFqsZXDUE0hagb4CLY/mMLi4/rVIGrS2o+Tw0nmL6Cuq/afQ0UoAv2QwcMHP0F9vK/kYwvbhoAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 16px;
  padding-left: 30px !important; }

.input-with-mail-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAeCAYAAABJ/8wUAAAAAXNSR0IArs4c6QAAAp5JREFUWAnNlk2ITmEUx6/BQr6GRlgNJrJBWUyhfDQLkZQSyWLIQlkgWxNZsFDKRpOyI6VkMSywUT4iK6lZIEZS5CPGVxiG33+6/9vjdt937p33vfM69Ztz7j3POc//fe59njtRFEXd8Bv+NAjN3T0mFtGEb6QNSohWQnYZbg9Fo/dnIVPt9HR+JPt8YxT9euYamr/RjyT5zf+lkA7kTUoklh+MYwo9msT8jsg/gfYkU17QRut7EM79z4USA3AQxkIZtoOmnyEUoTi5cY24P7i+RdwK9bJmGl0AC/hO3BNcJwlt3zlwJ0h+JN4GtdoqGrwAi+glXgybgntJ0ueIHslh+BUMOks8BYraeAqOQfgJOcX1hLhRVSHxmGg5wTPwr+gjXuFkDj+fMffB9W+IN6TqcglRjVbhHLiZVukIaOtVs10kv4DrrhLPyijILcS12wn6wY3vEs9zMvDTiS+Cx+mF3A/6pmVZYSFqchI8gfwn6ATbGoKXEI655GQFX1jIZhoNxpM8xYcvsrbk8SAvIY/Bgg4QV7JCQpbQ5SuosbbgTNBL2weezP4t9zbCRHgQ5yV6LWRZbiEzqH4OmugbLAXbVILzYBHXiWc7iW8F7RTlP8ACSFsuIToDboIn2pruEl+vw2+BrBdyJfd/gno8gmYILZeQ01RYxNGwumC8O+ijbawD0zaskD2MtIge4qxf62Z5vE5T9zsRFFQVspqBA3FhL34y1Go6AG+AxXTGDSsKmcuAd3HBe3xbXFAP10ITfzJ00C2DTCFdJB6CVGtFOqDetoiG/l/kFfFe8ColgQcooQFlmVbBh6PPJ82ZCLGyM2UpCPoeSs+b/or+YMBr0MAyTbtQh9w0T6IbWqZat6f7jdg3UTncF3LEzQsUXvkLpqwoz/7SEb4AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 16px;
  padding-left: 30px !important; }

.input-with-group-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAkCAYAAACaJFpUAAAAAXNSR0IArs4c6QAAAJxJREFUSA3tl0EOwCAIBGnTz/X/T7D/sGLcm2JNgXigCcFKwrKjF4mI7hKpRDYO1mAtFzGYSUdzxcK8tvxYlE5LhV7vEOxR+bXnjvQSxq23SqiPSuJt38qhOOnI3mx/K4dfz3CJxFYOlyafnR3q7g5DEOjVciBVQ4lGgRQk1HIgVUOJRoEUJNRyIFVDiUaM9Gk/eDRaZZapWq5P7hfb124n4s2p8AAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 16px;
  padding-left: 30px !important; }

.input-with-check-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAAAXNSR0IArs4c6QAAA25JREFUSA3FlWtIVEEUx+fM3rXSwqAHEQS5u+W6ahRB0KdoFaQiKEI/FEhBJATVh7IoSieLQIQIITLoQUVFRVD0lCwogiCSIPf6qF03sKCHve2xtXtPZ5Sxu7uzsuKj+TIz/3Nmzu/OzDmXsf/QqkzXzmozp84emtsnozEWLS7BLDyAFttWHcjZp2KCGoxGXx1w1SLidnssDnyPKAjtHzUQYboOWRZusUOoMWe80lCTkerpBGBvwH2EICpSxuBojeiJCBQczdPHCWatFgIYkr6ppiB8eMRALmKpwzSbTxPEah0EAJOnUCEKwsekfURAjuJ852vz03mGuEoHQVqMMce6msLgGWUfdpD6F54x3RHrEkEsV0HsPTCIAudrRH7wol0f1jpysGvhuO5I7GpKCIDf4IDSRAgJFAciH5edcjDjujdzsj5/eXuDnl+Jbh29iV/A2UrhC13R2fsDyzRj5qkTMt91jgNpte25E3q6e27TSSzW+dH9/wAOy4Wv86bOLrXeNyIhCKABkW3oE6GBqt1GAJDpNWAT4bkT8fvXRtpjgc6R9ugB7lgmfC8e6OxK6wURLTn1FuWzEmXPGZxkBeXrBQgy6duBNu+kX7HIHbqOeXoP+GIYfElVXvCR3v5PBV39V2b6mnP5+fPLy+ASpVt8E0H3VOsnNjGGhfGWvhm9iY9gQInwdj7R2RM1jsgDJCYFko6yGAXM5guyLtgXivbc6fjTup8Kgm78vYM5/elCyL37rsb0lKFlnUWG+n8PwLXJY3jp5lnByP4W94w/gPcI0mOHU2M6iTcAjiJK0ValpdP3gkhH0epegRZeoAAZ2oXAGg1wbI1h7Do96pk6HypWr8BgRSKv87nOPpDWDyKdRKtrKcFcpkBjdYvk/4Fs/Slv9yHbS8PI8O/xdoTterrjOBC5iGCKMYZXKW8z094EIOhE8O8uDHWluybRL+nrqOg0gcNYIvM/0Vk3py9pB8O5aCgQct+kE1HBato8C6NR6xZlRrbSknto4eOgWHhC75Jtg1OSTkQtl0WIO1mxrAdKi+uBPR1rZCweDgi5b0oQaZR1QNYDWRfkXDW6tsc8K9u/K6/9g9KG2qe8GvvGwvT4EGN3KWOmEcTDTMO5dIe345vdZ6jjtEBkENHmms2irCpryviKymnPvg81cOL6vyL9P8cDsQGkAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 16px; }

.input-with-cross-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAFqADAAQAAAABAAAAFgAAAAAcITNaAAACiElEQVQ4EdVUPWgUQRSemb0fLNJpJRa3u+GSu1yTCBYROUW5QjSInoVFYhrlUET8xWBliKAI6aIWonbB9AkYsDGms3HvLhfM7pXGLqAc4v6M3yyZZXazCbZZON573/e9N++921lC9v3T1PVnLdMc+99BWv39x9K0TIK8XtcsXX/DOX/AfX/eMs2q5Haz0De4562KZpIaKoFmofCBE1KXMaX0l0bpyUHb/iox1TYN4zEPgmmJUcYeDtn2cxlHHaPQkgSFRed9HudLnWKxmMApOpxVi4Y859PQFqQ2Klx2nLcI7kliW3zIc91lyzCOiJhXq5mWrr/DobdjOkJ6aGxsYH29K/FoFRLA3p6i3Ucy3radXDZ72nXdOazrfILbIoydrdj2qorvKCxI7Ps1ClxThQQ7x4F9KobkzQwhtYFu95uKCz9ahUqUJyYaSFpQsWRRcA6mGE0rKvJSOxZEq1zOBb3eMtwTIo49lFpaPl8rra39iOFKkNqx4DOuq2N8Q9FGLrqxBtvtzQhIcVILd3T9qOt5nzH+4ZQcwPxKyzBepHES21EYCac8Qj4h+aAUCYsuf6sx+DtWoTClYqofK9w2jAtIWMQv9u9jJa8YpcOwP9Vk+DNo5HoCC8OoMF78ST8IFlA0rwoZYzMVx2mUHOc7zWRq4LZUHjdwDh+tyyom/PCtwL0fhWBFJUFwdHh3yHFmVRwNHA84/wjsgMRx6/5yxs5VNjYEHj5hx/h4fMGoLyUI6+M2TSaLCh5XfwUfp0s41JV6TJljvn9GxsJGqyiNj9/EyfPo9A/TtIs47L0qVH2sZRGJV8OpQCDvSbnbva9qYj4fGcm2TXM4Bu4R4OrfwJ93aw/JPqL+AQty7DqUbf6PAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 16px; }

.page-nav--red-alert {
  width: 15px;
  margin: 5px; }

.form-group-with-charCoder {
  position: relative; }

/* Button */
.button-primary {
  border: none !important;
  border-color: white !important;
  border-radius: 0 !important;
  background-image: linear-gradient(#00adef, #03a0df 30%, #0887be);
  margin-bottom: 15px;
  padding: 3pt 30pt;
  font-family: "CorporateS-Bold", sans-serif;
  color: #fff; }

.black-button {
  color: #fff;
  font-family: "CorporateS-Bold";
  margin: 5px 4px;
  padding: 4pt 12pt;
  border: none !important;
  background-image: linear-gradient(to bottom, #737373, #5a5a5a); }

.close-button {
  text-shadow: none;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: xx-large;
  position: fixed;
  right: 10px;
  top: 100px;
  padding: 20px; }

.max-length-error {
  border: 1px solid #ff6760 !important;
  box-shadow: none !important; }

/* Common input border */
.input-border {
  border: 1px solid #ececec !important;
  border-radius: 0.2em !important; }

/* Alignment */
.align-center {
  display: flex;
  align-items: center; }

