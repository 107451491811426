.m-t-5 {
  margin-top: 5px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.m-r-35 {
  margin-right: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.m-r-40 {
  margin-right: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.m-t-45 {
  margin-top: 45px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.m-l-45 {
  margin-left: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.m-r-45 {
  margin-right: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.m-r-50 {
  margin-right: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.m-t-75 {
  margin-top: 75px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.m-b-75 {
  margin-bottom: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.m-l-75 {
  margin-left: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.m-r-75 {
  margin-right: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.m-t-100 {
  margin-top: 100px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.m-l-100 {
  margin-left: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.m-r-100 {
  margin-right: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

/* Required field (*) color */
.color-primary {
  color: #00adef; }

/* Form update button */
.update-text {
  font-family: "CorporateS-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  margin-bottom: 0pt;
  margin-left: 20px; }

/* Input with icon */
.input-with-currency-icon {
  background-image: url(../../assets/img//loyaltyProgram/currency.png);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 12px;
  padding-left: 30px !important; }

.input-with-phone-icon {
  background-image: url(../../assets/img/phone@2x.png);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 16px;
  padding-left: 30px !important; }

.input-with-mail-icon {
  background-image: url(../../assets/img/envelope@2x.png);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 16px;
  padding-left: 30px !important; }

.input-with-group-icon {
  background-image: url(../../assets/img/group@2x.png);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 16px;
  padding-left: 30px !important; }

.input-with-check-icon {
  background-image: url(../../assets/img/check@2x.png);
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 16px; }

.input-with-cross-icon {
  background-image: url(../../assets/img/redcross@2x.png);
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 16px; }

.page-nav--red-alert {
  width: 15px;
  margin: 5px; }

.form-group-with-charCoder {
  position: relative; }

/* Button */
.button-primary {
  border: none !important;
  border-color: white !important;
  border-radius: 0 !important;
  background-image: linear-gradient(#00adef, #03a0df 30%, #0887be);
  margin-bottom: 15px;
  padding: 3pt 30pt;
  font-family: "CorporateS-Bold", sans-serif;
  color: #fff; }

.black-button {
  color: #fff;
  font-family: "CorporateS-Bold";
  margin: 5px 4px;
  padding: 4pt 12pt;
  border: none !important;
  background-image: linear-gradient(to bottom, #737373, #5a5a5a); }

.close-button {
  text-shadow: none;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: xx-large;
  position: fixed;
  right: 10px;
  top: 100px;
  padding: 20px; }

.max-length-error {
  border: 1px solid #ff6760 !important;
  box-shadow: none !important; }

/* Common input border */
.input-border {
  border: 1px solid #ececec !important;
  border-radius: 0.2em !important; }

/* Alignment */
.align-center {
  display: flex;
  align-items: center; }
