@font-face {
  font-family: 'CorporateACon-Reg';
  src: 
    url('./assets/fonts/CorporateACon-Reg.OTF') format('opentype'),
    url('./assets/fonts/DaimlerCAC-Regular.woff') format('woff');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'CorporateS-Regular';
  src: 
    url('./assets/fonts/CorporateS-Regular.TTF') format('truetype'),
    url('./assets/fonts/DaimlerCS-Regular.woff') format('woff');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'CorporateS-Bold';
  src: 
    url('./assets/fonts/CorporateS-Bold.TTF') format('truetype'),
    url('./assets/fonts/DaimlerCS-Bold.woff') format('woff');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'CorpoSLig-Bold';
  src: 
    url('./assets/fonts/CorpoSLig-Bold.TTF') format('truetype'),
    url('./assets/fonts/DaimlerCS-Bold.woff') format('woff');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'CorpoSDem-Bold';
  src: 
    url('./assets/fonts/CorpoSDem.TTF') format('truetype'),
    url('./assets/fonts/DaimlerCS-Bold.woff') format('woff');
  font-weight: normal;
	font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "CorporateS-Regular",  sans-serif;
}