input,
textarea,
select,
.input-group-text {
  border: 1px solid #ececec !important;
  border-radius: 0.2em !important;
  background-color: #fff; }

input[type="checkbox"],
input[type="radio"] {
  margin: 0.3rem; }

.form-control {
  height: 38px;
  font-size: 14px; }

.form-control:disabled {
  background-color: #f7f7f7 !important;
  color: #a7a7a7; }

.form-control[readonly] {
  background-color: #fff; }

.form-row {
  align-items: center; }

option.placeholder {
  color: #999; }

textarea {
  max-height: 200px;
  min-height: 38px; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url("../img/dropdown@2x.png");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding: 0 30px 0 0.75rem !important;
  font-size: "14px";
  height: "38px"; }

select::-ms-expand {
  display: none; }

button:disabled {
  opacity: 0.65; }
